import React from "react";
import { Trans, useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";

import Book from "../../../static/images/book-cover.webp";
import AnimatedSvg from "../../components/AnimatedSvg";
import { Container } from "../../components/Container";
import { SendReportButton } from "../../components/SendReport/SendReportButton";

const bookText = css`
  ${tw`w-full md:w-3/5`}
  h1 {
    ${tw`text-5xl md:(text-6xl)`}
    > span {
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }
  }
`;
const freeReport = css`
  ${tw`py-0 pt-10`}
  section {
    ${tw`text-center sm:text-left`}
  }
  article {
    ${tw`max-w-5xl mx-auto flex flex-wrap items-center`}
  }
  h1,
  h2 {
    ${tw`text-4xl lg:text-5xl font-black my-4`}
  }
  p {
    ${tw`text-base sm:text-xl`}
  }
  img {
    ${tw`mx-auto mb-8 w-2/3 sm:w-auto`}
  }
`;

const FreeReport: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();

  return (
    <section css={freeReport}>
      <Container>
        <article tw="flex-row-reverse" {...rest}>
          <div tw="w-full md:w-2/5">
            <AnimatedSvg>
              <img src={Book} alt={t("optIn.bookCoverAlt")} tw="scale-95" />
            </AnimatedSvg>
          </div>
          <div css={bookText}>
            <p>{t("optIn.attention")}</p>
            <h1>
              <Trans i18nKey={t("optIn.header")} components={{ span: <span /> }} />
            </h1>
            <p>{t("optIn.subheader")}</p>
          </div>
          <SendReportButton tw="mx-auto my-12" skipComment={true} isBig={true} />
        </article>
      </Container>
    </section>
  );
};

export default FreeReport;
