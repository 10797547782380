import React from "react";
import { useTranslation } from "react-i18next";
import tw, { css, styled } from "twin.macro";

import Svg1 from "../../static/images/about-1.svg";
import Svg2 from "../../static/images/about-2.svg";
import Svg3 from "../../static/images/about-3.svg";
import HowItWorksPreview from "../../static/images/howItWorksPreview.jpg";
import MaterialOwnerSvg from "../../static/images/material-owners.svg";
import AnimatedSvg from "../components/AnimatedSvg";
import { BookMeeting } from "../components/BookMeeting";
import PartnersCarousel from "../components/Carrousel";
import { Container } from "../components/Container";
import DarkContainer from "../components/DarkContainer";
import { Layout } from "../components/Layout/Layout";
import ProfileTypeDescription from "../components/ProfileTypeDescription";
import { getTranslationList } from "../utils/common";
import HowItWorksCards from "./how_it_works/HowItWorksCards";
import FreeReport from "./opt_in/FreeReport";

const baseFeature = css`
  ${tw`mb-3 pb-3 sm:(mb-0 pb-0) items-start text-center max-w-3xl mx-auto`}
  article {
    ${tw`pb-4`}
  }
  h2 {
    ${tw`font-black text-5xl mb-8`}
  }
  span {
    ${tw`sm:pt-8`}
  }
  svg {
    ${tw`mb-4 fill-current text-turquoise w-40 mx-auto sm:(my-auto mx-12)`}
    min-width: 10rem;
  }
  li > svg {
    ${tw`inline h-6 ml--2 mr-1 mt--1 mb-auto transition w-auto`}
    min-width: 1rem;
  }
  li {
    ${tw`mx-4`}
  }
`;

const benefits = css`
  ${tw`mx-auto text-center `}
  h2 {
    ${tw`font-black text-5xl max-w-prose`}
  }
  p {
    ${tw`mx-auto max-w-prose`}
  }
  div#bookMeeting {
    ${tw`py-8`}
  }
`;

const cards = css`
  ${tw`py-8 max-w-5xl mx-auto flex flex-wrap items-center`}
`;

const about = css`
  section {
    ${tw`my-0 py-4`}
  }
  > div {
    ${tw`max-w-2xl mx-auto text-center`}
    > h2 {
      ${tw`font-black text-5xl`}
    }
    > p {
      ${tw`mt-8 mb-4 text-2xl`}
    }
  }
`;

const freeReport = css`
  ${tw`py-0`}
  section {
    ${tw`text-center sm:text-left`}
  }
  article {
    ${tw`max-w-5xl mx-auto flex flex-wrap items-center`}
  }
  h1,
  h2 {
    ${tw`text-4xl lg:text-5xl font-black my-4`}
  }
  p {
    ${tw`text-base sm:text-xl`}
  }
  img {
    ${tw`mx-auto mb-8 w-2/3 sm:w-auto`}
  }
`;
const partners = css`
  section {
    ${tw`pb-8 pt-6`}
  }
  article {
    ${tw`block text-center`}
  }
  div#bookMeeting {
    ${tw`pt-8`}
  }
`;

const Section = styled.section`
  ${tw`md:flex justify-center items-center my-16`}

  p {
    ${tw`text-xl max-w-xl`}
  }

  svg {
    ${tw`fill-current text-turquoise w-32 my-8 mx-auto md:(m-0 mr-8)`}
  }
`;

const sectionPadding = css`
  section {
    ${tw`py-8`}
  }
  div#bookMeeting {
    ${tw`flex-none mt-0 max-w-xl mx-auto`}
  }
`;

const HowItWorks: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();

  const materialOwnerList = getTranslationList(t, "index.materialOwner.list");

  return (
    <Layout
      css={sectionPadding}
      title={"How it works"}
      ogTitle={"How it works"}
      author={"Triangular-pod"}
      ogImage={HowItWorksPreview}
      ogDescription={
        "We believe it is possible to develop and process new materials in a sustainable way. At Triangular-POD demand meets capacity enriched with know-how. Learn how our platform works in just a few easy steps."
      }
      ogURL={"www.triangular-pod.com/how-it-works/"}
    >
      <Container {...rest}>
        <section css={baseFeature}>
          <ProfileTypeDescription header={t("index.materialOwner.header")} list={materialOwnerList}>
            <MaterialOwnerSvg />
          </ProfileTypeDescription>
          <BookMeeting label={t("howItWorks.bookMeeting")} />
        </section>
      </Container>
      <DarkContainer>
        <Container {...rest}>
          <section css={cards}>
            <HowItWorksCards />
            <p tw="my-8 mx-auto text-center max-w-prose text-xl">{t("howItWorks.cardSection.weProvideAccess")}</p>
            <BookMeeting label={t("howItWorks.bookMeeting")} />
          </section>
        </Container>
      </DarkContainer>
      <Container {...rest}>
        <section css={about}>
          <div>
            <h2>{t("about.header")}</h2>
            <p>{t("about.subheader")}</p>
          </div>
          <Section>
            <AnimatedSvg>
              <Svg1 />
            </AnimatedSvg>
            <p>{t("about.people")}</p>
          </Section>
          <Section>
            <AnimatedSvg>
              <Svg2 />
            </AnimatedSvg>
            <p>{t("about.aim")}</p>
          </Section>
          <Section id="aboutPlatform">
            <AnimatedSvg>
              <Svg3 />
            </AnimatedSvg>
            <p>{t("about.platform")}</p>
          </Section>
        </section>
      </Container>
      <DarkContainer>
        <Container css={partners} {...rest}>
          <section>
            <h3 tw="text-5xl font-black text-center">{t("howItWorks.partnersHeeader")}</h3>
            <PartnersCarousel />
            <BookMeeting label={t("howItWorks.bookMeeting")} />
          </section>
        </Container>
      </DarkContainer>
      <Container>
        <section css={freeReport}>
          <FreeReport />
        </section>
      </Container>
      <DarkContainer>
        <Container {...rest}>
          <section css={benefits}>
            <h2>{t("benefits.header")}</h2>
            <p tw="mt-8 mb-16 text-2xl">{t("benefits.subheader")}</p>
            <p tw="font-bold text-xl">{t("howItWorks.benefits.weAreConvinced")}</p>
            <BookMeeting label={t("howItWorks.bookMeeting")} />
          </section>
        </Container>
      </DarkContainer>
    </Layout>
  );
};

export default HowItWorks;
